<template>
  <div class="tab-content h-80" id="PTtab-5" :key="'PTtab-5'">
      <div class="pagenamPT">
        <h3 class="pagename2">{{ $t('front.common.access') }}</h3>
      </div>
      <div class="partnerTop">
        <div class="select">
          <a>{{ $t('front.common.latest') }}</a>
          <a>{{ $t('front.common.byID') }}</a>
          <a>{{ $t('front.common.byMoney') }}</a>
          <a>{{ $t('front.common.byRolling') }}</a>
        </div>
        <date-filter-Pt @search="goToPage(1)"/>
      </div>
      <div class="strTableWrap">
        <PartnerHierarchy @onClickMemId="loadSubList" :treeOption="treeOption" @onUpdateTreeOption="onUpdateTreeOption"></PartnerHierarchy>
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="strTablePT">
              <colgroup>
                <col width="8%" />
                <col width="8%" />
                <col width="5%" />
                <col width="10%" />
                <col width="10%" />
                <col width="5%" />
                <col width="10%" />
                <col width="12%" />
                <col width="12%" />
                <col width="10%" />
                <col width="5%" />
                <col width="5%" />
              </colgroup>
              <thead>
                <tr>
                  <th>{{ $t('front.common.memId') }}<br>{{ $t('front.common.nickName') }}</th>
                  <th>{{ $t('front.common.department') }}</th>
                  <th>{{ $t('front.common.depositor') }}</th>
                  <th>{{ $t('front.cash.nav3') }}<br>{{ $t('front.cash.nav4') }}</th>
                  <th>{{ $t('front.stributor.m5') }}<br>{{ $t('front.stributor.m6') }}</th>
                  <th>{{ $t('front.common.inTotal') }}/{{ $t('front.cash.exchange') }}</th>
                  <th>{{ $t('front.common.inTotal') }} {{ $t('front.common.deposit') }}<br>{{ $t('front.common.inTotal') }} {{ $t('front.common.withdrawal') }}</th>
                  <th><span class="fs12">{{ $t('front.bettingStatus.BET') }} / {{ $t('front.common.winning') }} / {{ $t('front.stributor.roll') }} / {{ $t('front.common.acquiRoll') }}</span><br />({{ $t('front.gnb.casino') }})</th>
                  <th><span class="fs12">{{ $t('front.bettingStatus.BET') }} / {{ $t('front.common.winning') }} / {{ $t('front.stributor.roll') }} / {{ $t('front.common.acquiRoll') }}</span><br />({{ $t('front.gnb.slot') }})</th>
                  <th>{{ $t('front.common.recentGame') }}</th>
                  <th>{{ $t('front.common.moveTo') }}</th>
                  <th>{{ $t('front.common.details') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="userList && userList.length > 0">
                  <tr v-for="(user, index) in userList" :key="user.memId">
                    <td style="line-height: 2;">{{user.memId}}<br><em class="nick">{{user.memNick}}</em></td>
                    <td>
                      <div class="topwrap" v-if="user.topUserList && user.topUserList.length > 0 && user.topUserList !== '0'">
                        <select class="h20 upperUser">
                          <option v-for="pt in user.topUserList" :key="pt.recommenderId">
                            <span>[{{pt.partnerLevelName}}] </span>
                            <span>{{pt.recommenderId}}</span>
                          </option>
                        </select>
                        <i class="icon">+</i>
                      </div>
                      <div v-else>
                        -
                      </div>
                    </td>
                    <td>{{ user.memName }}</td>
                    <td>
                      <div class="twoline-wrap">
                        <span>{{thousand(user.cashAmt || 0)}}</span>
                        <span class="nick">{{thousand(user.pointAmt || 0)}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="twoline-wrap">
                        <span>{{thousand(user.payment || 0)}}</span>
                        <span>{{thousand(user.payback || 0)}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="btn-input-wrap">
                        <div><a class="inout-btn btn-green" @click="onOpenPay(user, index)">{{ $t('front.give.give') }}{{ $t('front.give.back') }}</a></div>
                      </div>
                    </td>
                    <td>
                      <div class="twoline-wrap">
                        <span>{{thousand(user.cashIn || 0)}}</span>
                        <span class="plusAmt minusAmt">{{thousand(user.cashOut || 0)}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="twoline-wrap">
                        <span>{{thousand(user.casinoBetAmt || 0)}}</span>
                        <span>{{thousand(user.casinoWinAmt || 0)}}</span>
                        <span>{{thousand(user.casinoRolling || 0)}}</span>
                        <span>{{thousand(user.byCasino || 0)}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="twoline-wrap">
                        <span>{{thousand(user.slotBetAmt || 0)}}</span>
                        <span>{{thousand(user.slotWinAmt || 0)}}</span>
                        <span>{{thousand(user.slotRolling || 0)}}</span>
                        <span>{{thousand(user.bySlot || 0)}}</span>
                      </div>
                    </td>
                    <td>{{user.betData}}</td>
                    <td>
                      <div class="btn-input-wrap">
                        <a class="inout-btn btn-beige" @click="goToDetail(user.memId)">{{ $t('front.mypage.betList') }}</a>
                      </div>
                    </td>
                    <td>
                      <div class="btn-input-wrap">
                        <a class="inout-btn btn-orange" @click="goToDetail(user.memId)">{{ $t('front.stributor.detailMore') }}</a>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="12">접속 중인 회원이 없습니다.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
              <div class="strTablePTM">
                <template v-if="userList && userList.length > 0">
                  <ul v-for="(user, index) in userList" :key="user.memId">
                    <li>
                      <em>아이디<br />닉네임</em>
                      <div>
                        <span>{{user.memId}}</span>
                        <span class="nick">{{user.memNick}}</span>
                      </div>
                    </li>
                    <li>
                      <em>소속</em>
                      <div class="topwrap" v-if="user.topUserList && user.topUserList.length > 0 && user.topUserList !== '0'">
                        <select class="h20 upperUser mw-auto">
                          <option v-for="pt in user.topUserList" :key="pt.recommenderId">
                            <span>[{{pt.partnerLevelName}}] </span>
                            <span>{{pt.recommenderId}}</span>
                          </option>
                        </select>
                        <i class="icon">+</i>
                      </div>
                      <div v-else>
                        -
                      </div>
                    </li>
                    <li>
                      <em>예금주 명</em>
                      <div>
                        <span>{{ user.memName }}</span>
                      </div>
                    </li>
                    <li>
                      <em>보유머니<br />보유포인트</em>
                      <div>
                        <span>{{thousand(user.cashAmt || 0)}}</span>
                        <span class="nick">{{thousand(user.pointAmt || 0)}}</span>
                      </div>
                    </li>
                    <li>
                      <em>머니지급<br>머니회수</em>
                      <div>
                        <span>{{thousand(user.payment || 0)}}</span><br />
                        <span>{{thousand(user.payback || 0)}}</span>
                      </div>
                    </li>
                    <li>
                      <em>충 / {{ $t('front.cash.exchange') }}</em>
                      <div><a class="inout-btn" @click="onOpenPay(user, index)">지급회수</a></div>
                    </li>
                    <li>
                      <em>총 입금<br />총 출금</em>
                      <div>
                        <span>{{thousand(user.cashIn || 0)}}</span>
                        <span class="plusAmt minusAmt">{{thousand(user.cashOut || 0)}}</span>
                      </div>
                    </li>
                    <li>
                      <em>{{ $t('front.bettingStatus.BET') }} / {{ $t('front.common.winning') }}<br />롤링 / 획득롤링<br />(카지노)</em>
                      <div>
                        <span>{{thousand(user.casinoWinAmt || 0)}}</span>/
                        <span>{{thousand(user.casinoBetAmt || 0)}}</span><br />
                        <span>{{thousand(user.casinoRolling || 0)}}</span>/
                        <span>{{thousand(user.byCasino || 0)}}</span>
                      </div>
                    </li>
                    <li>
                      <em>{{ $t('front.bettingStatus.BET') }} / {{ $t('front.common.winning') }}<br />롤링 / 획득롤링<br />(슬롯)</em>
                      <div>
                        <span>{{thousand(user.slotWinAmt || 0)}}</span>/
                        <span>{{thousand(user.slotBetAmt || 0)}}</span><br />
                        <span>{{thousand(user.slotRolling || 0)}}</span>/
                        <span>{{thousand(user.bySlot || 0)}}</span>
                      </div>
                    </li>
                    <li>
                      <em>최근실행게임</em>
                      <div>{{user.betData}}</div>
                    </li>
                    <li>
                      <em>이동</em>
                      <div><a class="inout-btn btn-beige" @click="goToDetail(user.memId)">베팅내역</a></div>
                    </li>
                    <li>
                      <em>상세정보</em>
                      <div><a class="inout-btn color-btn" @click="goToDetail(user.memId)">상세보기</a></div>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
        </div>
      </div>
      <pagination v-if="pageInfo"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="goToPage"
      />
    <transition name="fade">
      <RateTable v-if="RateOpen" :selectMem="selectMem" :selectIdx="selectIdx"  @close="onCloseRate"/>
    </transition>
    <transition name="fade">
      <Paymodal v-if="PayMopen" :selectMem="selectMem" :selectIdx="selectIdx" @close="onClosePay"/>
    </transition>
  </div>
</template>

<script>
import DateFilterPt from '@/components/ui/DateFilterPt'
import Paymodal from '@/components/common/Pay.vue'
import Pagination from '@/components/ui/Pagination.vue'
import { mapState } from 'vuex'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { getUserOnlineListV2 } from '@/api/v2/user'
import { getPartnerHierarchyV2 } from '@/api/v2/partner'
import RateTable from '@/components/common/RateTable.vue'
import PartnerHierarchy from '@/components/common/mobile/PartnerHierarchy.vue'
import { PARTNER_LEVEL_ORDER } from '@/libs/constants'
export default {
  name: 'partnerUserList',
  components: { PartnerHierarchy, RateTable, DateFilterPt, Pagination, Paymodal },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  data () {
    return {
      RateOpen: false,
      PayMopen: false,
      partnerList: null,
      selectIdx: null,
      selectMem: null,
      selectedLi: null,
      userList: null,
      userListOrder: 'JOIN_DESC',
      startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
      endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59'),
      treeOption: 'N'
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    await this.getHierarchyList('', 'main', null)
    await this.loadSubList(this.userData.memId, 'main', 1)
    this.selectedLi = this.userData.memId
    this.emitter.emit('Loading', false)
  },
  watch: {
    userListOrder () {
      this.loadSubList(this.selectedLi, 1, 'sub')
    }
  },
  methods: {
    thousand,
    onUpdateTreeOption (value) {
      this.treeOption = value
    },
    onCloseRate () {
      this.RateOpen = false
      this.selectMem = null
      this.selectIdx = null
    },
    onClosePay (newCash, idx) {
      if (newCash !== null) {
        console.log(this.index, newCash)
        this.$emit('updateCash', this.data, this.index, newCash)
      }
      this.PayMopen = false
      this.selectMem = null
      this.selectIdx = null
    },
    onOpenPay (selectMem, selectIdx) {
      this.selectMem = {
        ...selectMem,
        cashAmt: thousand(selectMem.cashAmt)
      }
      this.selectIdx = selectIdx
      this.PayMopen = true
    },
    onOpenRate (selectMem, selectIdx) {
      this.selectMem = {
        ...selectMem,
        cashAmt: thousand(selectMem.cashAmt)
      }
      this.selectIdx = selectIdx
      this.RateOpen = true
    },
    goToDetail (memId) {
      this.detailOpen('user', memId)
    },
    goToPage (page) {
      this.loadSubList(this.selectedLi, 'sub', null, page)
    },
    async loadSubList (memId, type, page) {
      this.userList = null
      if (!page) {
        page = this.pageInfo.page
      } else {
        this.pageInfo.page = page
      }
      this.emitter.emit('Loading', true)

      const params = {
        memId: memId,
        targetLevel: '',
        searchType: 'id',
        searchValue: '',
        startDate: this.startDate,
        endDate: this.endDate,
        orderStr: this.userListOrder,
        page: page,
        count_per_list: 40,
        treeOption: this.treeOption
      }

      console.log(params)

      await getUserOnlineListV2(params, type).then(res => {
        this.emitter.emit('Loading', false)
        console.log('getUserListV2 : ', res)
        const result = res.data
        if (result.resultCode === '0') {
          this.userList = result.data.list
          this.userList.forEach(item => {
            const topUserList = []
            if (item.topUserList) {
              item.topUserList.forEach(pt => {
                if (this.userData.partnerLevelOrder <= PARTNER_LEVEL_ORDER[pt.partnerLevel]) {
                  topUserList.push(pt)
                }
              })
            }
            item.topUserList = topUserList
          })
        }

        if (result.data.pageInfo) {
          this.pageInfo = result.data.pageInfo
        } else {
          this.pageInfo.page = 1
          this.pageInfo.tatal_list_count = 0
        }
      })
    },
    getHierarchyList (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      return getPartnerHierarchyV2(params).then(res => {
        const result = res.data
        console.log('getPartnerHierarchyV2 : ', params, result)
        const list = result.data.hierarchy
        if (type === 'sub') {
          item.children = list
          item.open = !item.open
          if (this.selectedLi === memId) {
            this.selectedLi = memId
          } else {
            this.selectedLi = memId
          }

          // this.loadSubList(memId, 1, type)
        } else {
          this.partnerList = list
        }

        this.emitter.emit('Loading', false)

        return true
      })
    }
  }
}
</script>

<style src="@/styles/striNew.css"></style>
<style src="@/styles/pt.css"></style>
<style scoped>
.fs12 {font-size: 12px;}
.partnerTop {display: flex;align-items: center;justify-content: space-between;margin-bottom: 17px;}
.select {display: flex;align-items: center;gap: 3px;}
.select a {display: flex;align-items: center;justify-content: center;height: 30px;padding: 0 15px;border-radius: 3px;border: solid 1px #b1b8bb;box-sizing: border-box;background-color: #fff;font-size: 15px;color: #6b737c;cursor: pointer;}
.select a.on, .select a:hover {color: #fff;border: solid 1px #505459;background-color: #575b60;}

.partnerInfo {padding: 12px 10px 30px;margin-bottom: 30px;box-sizing: border-box;display: flex;flex-direction: column;gap: 30px;}
h4.title {font-size: 20px;font-weight: bold;letter-spacing: -1px;color: #000;margin-bottom: 10px;}
.partnerInfo ul {display: flex;flex-direction: column;gap: 12px;width: 273px;}
.partnerInfo ul li:first-child {font-size: 15px;font-weight: bold;color: #000;box-shadow: none;border: 0;}
.partnerInfo ul li {    display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e7e7e7;
  box-sizing: border-box;
  background-color: #fff;font-weight: bold;color: #3b3b3b;}
.partnerInfo ul li em {display: flex;
    align-items: center;
    justify-content: space-between;height: 30px;
  letter-spacing: -0.75px;
  border-bottom: 1px solid #e7e7e7;padding: 0 13px;}
.partnerInfo ul li span {display: flex;
    align-items: center;
    justify-content: space-between;height: 46px;font-size: 13px;letter-spacing: -0.65px;padding: 0 13px;}
.infoView {display: flex;align-items: center;justify-content: center;gap: 50px;}
.btnAdd {display: flex;align-items: center;margin-left: auto;margin-right: 50px;height: 27px;padding: 0 18px;border-radius: 4px;background-color: #363b72;color: #fff;font-size: 13px;}
.btn-select-wrap {display: flex;align-items: center;justify-content: center;}
.btn-select-wrap select {min-width: auto !important;height: 27px !important;border: solid 1px #c9c9c9 !important;padding: 0 0 0 5px !important;border-radius: 4px 0 0 4px !important;}
.btn-select-wrap .btnp {border-radius: 0 4px 4px 0 !important;}
.btnp {
  height: 27px;
  padding: 0px 15px;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #8376d4;
  color: #fff;
  display: flex;
  align-items: center;cursor: pointer;
}
.flex-c {
  flex-direction: column;
}
.gap-2 {
  gap: 2px;
}
@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
  .partnerTop {flex-direction: column;gap: 15px;}
  .select {width: 100%;}
  .select a {width: 100%;padding: 0 5px;font-size: 12px;}
}
.subPT_1 li {
  position: relative;
}
</style>
